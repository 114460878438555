

.wrapper {
  max-width: theme('screens.xl');
  @apply mx-auto;
  @apply px-4;
}

@screen md {

  .wrapper {
    @apply px-8;
  }

}

.blocks {
  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

