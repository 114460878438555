
/*
Default: 'Lato', 'Open Sans', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', '"Noto Sans"', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"', '"Noto Color Emoji"', sans-serif
*/

html { font-family: 'Inter', sans-serif; }

@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}

:target:before {
    content: "";
    display: block;
    height: 100px;
    margin: -100px 0 0;
}

